export const servers = [
  { kor: '루페온', eng: 'lupeon' },
  { kor: '실리안', eng: 'cilian' },
  { kor: '아만', eng: 'aman' },
  { kor: '카마인', eng: 'carmine' },
  { kor: '카제로스', eng: 'caseros' },
  { kor: '아브렐슈드', eng: 'abrelschud' },
  { kor: '카단', eng: 'cardan' },
  { kor: '니나브', eng: 'ninav' },
];

export const characters = [
  // 전사
  [
    {
      kor: '버서커',
      eng: 'berserker',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/berserker_m.png',
    },
    {
      kor: '워로드',
      eng: 'warlord',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/warlord_m.png',
    },
    {
      kor: '디스트로이어',
      eng: 'destroyer',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/destroyer_m.png',
    },
    {
      kor: '홀리나이트',
      eng: 'holyknight',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/holyknight_m.png',
    },
  ],
  // 무도가
  [
    {
      kor: '배틀마스터',
      eng: 'battlemaster',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/battlemaster_m.png',
    },
    {
      kor: '인파이터',
      eng: 'infighter',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/infighter_m.png',
    },
    {
      kor: '기공사',
      eng: 'soulmaster',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/soulmaster_m.png',
    },
    {
      kor: '창술사',
      eng: 'lancemaster',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/lancemaster_m.png',
    },
    {
      kor: '스트라이커',
      eng: 'striker',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/striker_m.png',
    },
  ],
  // 헌터
  [
    {
      kor: '데빌헌터',
      eng: 'devilhunter',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/devilhunter_m.png',
    },
    {
      kor: '블래스터',
      eng: 'blaster',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/blaster_m.png',
    },
    {
      kor: '호크아이',
      eng: 'hawkeye',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/hawkeye_m.png',
    },
    {
      kor: '스카우터',
      eng: 'scouter',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/scouter_m.png',
    },
    {
      kor: '건슬링어',
      eng: 'gunslinger',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/gunslinger_m.png',
    },
  ],

  // 마법사
  [
    {
      kor: '아르카나',
      eng: 'arcana',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/arcana_m.png',
    },
    {
      kor: '바드',
      eng: 'bard',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/bard_m.png',
    },
    {
      kor: '서머너',
      eng: 'summoner',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/summoner_m.png',
    },
    {
      kor: '소서리스',
      eng: 'sorceress',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/sorceress_m.png',
    },
  ],
  // 암살자
  [
    {
      kor: '블레이드',
      eng: 'blade',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/blade_m.png',
    },
    {
      kor: '데모닉',
      eng: 'demonic',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/demonic_m.png',
    },
    {
      kor: '리퍼',
      eng: 'reaper',
      src: 'https://cdn-lostark.game.onstove.com/2018/obt/assets/images/common/thumb/reaper_m.png',
    },
  ],
];

export const schedule = {
  daily: [
    {
      key: 'chaos',
      kor: '카오스 던전',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/17012ef4ba7d431daa0884121bb2d912.png',
      checked: false,
      checkCount: 2,
      done: 0,
      order: 0,
      repeat: 'daily',
    },
    {
      key: 'guardian',
      kor: '가디언 토벌',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
      checked: false,
      checkCount: 2,
      done: 0,
      order: 1,
      repeat: 'daily',
    },
    {
      key: 'epona',
      kor: '에포나 의뢰',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 2,
      repeat: 'daily',
    },
  ],
  weekly: [
    {
      key: 'weeklyEpona',
      kor: '주간 에포나 의뢰',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 0,
      repeat: 'weekly',
    },
    {
      key: 'challengeGuardian',
      kor: '도전 가디언 토벌',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 1,
      repeat: 'weekly',
    },
    {
      key: 'Abyss',
      kor: '어비스 던전',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 2,
      repeat: 'weekly',
    },
    {
      key: 'guild',
      kor: '길드 의뢰',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/75e60d5ac25a4577ab0f9efe6b9544f3.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 3,
      repeat: 'weekly',
    },
    {
      key: 'argos',
      kor: '아르고스',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/8a845a8afd6347acb7ec1d468435419a.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 4,
      repeat: 'weekly',
    },
    {
      key: 'baltan',
      kor: '발탄',
      src: '/assets/icon/baltan.png',
      checked: false,
      checkCount: 2,
      done: 0,
      order: 5,
      repeat: 'weekly',
    },
    {
      key: 'viacis',
      kor: '비아키스',
      src: '/assets/icon/viacis.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 6,
      repeat: 'weekly',
    },
    {
      key: 'cooxayton',
      kor: '쿠크세이튼',
      src: '/assets/icon/cooxayton.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 7,
      repeat: 'weekly',
    },
    {
      key: 'abrelschud',
      kor: '아브렐슈드',
      src: '/assets/icon/abrelschud.png',
      checked: false,
      checkCount: 6,
      done: 0,
      order: 8,
      repeat: 'weekly',
    },
  ],
  expedition: [
    {
      key: 'adventure',
      kor: '모험섬',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 0,
      repeat: 'daily',
    },
    {
      key: 'boss',
      kor: '필드 보스',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 1,
      repeat: 'daily',
    },
    {
      key: 'seaVoyage',
      kor: '항해 협동',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/e02005b3beac427690c3f97360bad562.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 2,
      repeat: 'daily',
    },
    {
      key: 'chaosGate',
      kor: '카오스 게이트',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 3,
      repeat: 'daily',
    },
    {
      key: 'phantom',
      kor: '유령선',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 4,
      repeat: 'weekly',
    },
    {
      key: 'challengeAbyss',
      kor: '도전 어비스 던전',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
      checked: false,
      checkCount: 2,
      done: 0,
      order: 5,
      repeat: 'weekly',
    },
    {
      key: 'rehearsal',
      kor: '리허설',
      src: '/assets/icon/cooxayton.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 6,
      repeat: 'weekly',
    },
    {
      key: 'dejavu',
      kor: '데자뷰',
      src: '/assets/icon/abrelschud.png',
      checked: false,
      checkCount: 4,
      done: 0,
      order: 7,
      repeat: 'weekly',
    },
  ],
};

// 데모 적용시 적용할 데이터
export const demoLocalData = {
  characters: [
    {
      server: 'aman',
      name: '데모',
      level: '',
      itemLevel: '',
      characterKey: 0,
      selectClass: '인파이터',
      schedule: {
        daily: [
          {
            key: 'chaos',
            kor: '카오스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/17012ef4ba7d431daa0884121bb2d912.png',
            checked: true,
            checkCount: 2,
            done: 2,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'guardian',
            kor: '가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'epona',
            kor: '에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 2,
            repeat: 'daily',
          },
        ],
        weekly: [
          {
            key: 'weeklyEpona',
            kor: '주간 에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 0,
            repeat: 'weekly',
          },
          {
            key: 'challengeGuardian',
            kor: '도전 가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 1,
            repeat: 'weekly',
          },
          {
            key: 'Abyss',
            kor: '어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 1,
            done: 1,
            order: 2,
            repeat: 'weekly',
          },
          {
            key: 'baltan',
            kor: '발탄',
            src: '/assets/icon/baltan.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
          {
            key: 'cooxayton',
            kor: '쿠크세이튼',
            src: '/assets/icon/cooxayton.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 7,
            repeat: 'weekly',
          },
          {
            key: 'abrelschud',
            kor: '아브렐슈드',
            src: '/assets/icon/abrelschud.png',
            checked: true,
            checkCount: 6,
            done: 0,
            order: 8,
            repeat: 'weekly',
          },
        ],
        expedition: [
          {
            key: 'adventure',
            kor: '모험섬',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'boss',
            kor: '필드 보스',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'chaosGate',
            kor: '카오스 게이트',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 3,
            repeat: 'daily',
          },
          {
            key: 'phantom',
            kor: '유령선',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 4,
            repeat: 'weekly',
          },
          {
            key: 'challengeAbyss',
            kor: '도전 어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
        ],
      },
    },
    {
      server: 'aman',
      name: '데모2',
      level: '',
      itemLevel: '',
      characterKey: 1,
      selectClass: '버서커',
      schedule: {
        daily: [
          {
            key: 'chaos',
            kor: '카오스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/17012ef4ba7d431daa0884121bb2d912.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'guardian',
            kor: '가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 2,
            done: 2,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'epona',
            kor: '에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 2,
            repeat: 'daily',
          },
          {
            key: 'custom0',
            kor: '커스텀 컨텐츠',
            custom: 'y',
            done: 0,
            checkCount: 3,
            repeat: 'daily',
            checked: true,
          },
        ],
        weekly: [
          {
            key: 'weeklyEpona',
            kor: '주간 에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 0,
            repeat: 'weekly',
          },
          {
            key: 'challengeGuardian',
            kor: '도전 가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 1,
            repeat: 'weekly',
          },
          {
            key: 'Abyss',
            kor: '어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 2,
            repeat: 'weekly',
          },
          {
            key: 'baltan',
            kor: '발탄',
            src: '/assets/icon/baltan.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
          {
            key: 'cooxayton',
            kor: '쿠크세이튼',
            src: '/assets/icon/cooxayton.png',
            checked: true,
            checkCount: 3,
            done: 3,
            order: 7,
            repeat: 'weekly',
          },
          {
            key: 'abrelschud',
            kor: '아브렐슈드',
            src: '/assets/icon/abrelschud.png',
            checked: true,
            checkCount: 6,
            done: 0,
            order: 8,
            repeat: 'weekly',
          },
        ],
        expedition: [
          {
            key: 'adventure',
            kor: '모험섬',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'chaosGate',
            kor: '카오스 게이트',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 3,
            repeat: 'daily',
          },
          {
            key: 'boss',
            kor: '필드 보스',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'phantom',
            kor: '유령선',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 4,
            repeat: 'weekly',
          },
          {
            key: 'challengeAbyss',
            kor: '도전 어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
        ],
      },
    },
    {
      server: 'aman',
      name: '데모3',
      level: '',
      itemLevel: '',
      characterKey: 2,
      selectClass: '건슬링어',
      schedule: {
        daily: [
          {
            key: 'chaos',
            kor: '카오스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/17012ef4ba7d431daa0884121bb2d912.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'guardian',
            kor: '가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'epona',
            kor: '에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 2,
            repeat: 'daily',
          },
          {
            key: 'custom0',
            kor: '커스텀 컨텐츠',
            custom: 'y',
            done: 3,
            checkCount: 3,
            repeat: 'daily',
            checked: true,
          },
        ],
        weekly: [
          {
            key: 'weeklyEpona',
            kor: '주간 에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 0,
            repeat: 'weekly',
          },
          {
            key: 'challengeGuardian',
            kor: '도전 가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 1,
            repeat: 'weekly',
          },
          {
            key: 'Abyss',
            kor: '어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 2,
            repeat: 'weekly',
          },
          {
            key: 'baltan',
            kor: '발탄',
            src: '/assets/icon/baltan.png',
            checked: true,
            checkCount: 2,
            done: 2,
            order: 5,
            repeat: 'weekly',
          },
          {
            key: 'cooxayton',
            kor: '쿠크세이튼',
            src: '/assets/icon/cooxayton.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 7,
            repeat: 'weekly',
          },
          {
            key: 'abrelschud',
            kor: '아브렐슈드',
            src: '/assets/icon/abrelschud.png',
            checked: true,
            checkCount: 6,
            done: 0,
            order: 8,
            repeat: 'weekly',
          },
        ],
        expedition: [
          {
            key: 'adventure',
            kor: '모험섬',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'chaosGate',
            kor: '카오스 게이트',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 3,
            repeat: 'daily',
          },
          {
            key: 'boss',
            kor: '필드 보스',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'phantom',
            kor: '유령선',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 4,
            repeat: 'weekly',
          },
          {
            key: 'challengeAbyss',
            kor: '도전 어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
        ],
      },
    },
    {
      server: 'aman',
      name: '데모4',
      level: '',
      itemLevel: '',
      characterKey: 3,
      selectClass: '홀리나이트',
      schedule: {
        daily: [
          {
            key: 'chaos',
            kor: '카오스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/17012ef4ba7d431daa0884121bb2d912.png',
            checked: true,
            checkCount: 2,
            done: 2,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'guardian',
            kor: '가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'epona',
            kor: '에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 2,
            repeat: 'daily',
          },
          {
            key: 'custom0',
            kor: '커스텀 컨텐츠',
            custom: 'y',
            done: 0,
            checkCount: 3,
            repeat: 'daily',
            checked: true,
          },
        ],
        weekly: [
          {
            key: 'weeklyEpona',
            kor: '주간 에포나 의뢰',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 0,
            repeat: 'weekly',
          },
          {
            key: 'challengeGuardian',
            kor: '도전 가디언 토벌',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 1,
            repeat: 'weekly',
          },
          {
            key: 'Abyss',
            kor: '어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 2,
            repeat: 'weekly',
          },
          {
            key: 'baltan',
            kor: '발탄',
            src: '/assets/icon/baltan.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
          {
            key: 'cooxayton',
            kor: '쿠크세이튼',
            src: '/assets/icon/cooxayton.png',
            checked: true,
            checkCount: 3,
            done: 0,
            order: 7,
            repeat: 'weekly',
          },
          {
            key: 'abrelschud',
            kor: '아브렐슈드',
            src: '/assets/icon/abrelschud.png',
            checked: true,
            checkCount: 6,
            done: 6,
            order: 8,
            repeat: 'weekly',
          },
        ],
        expedition: [
          {
            key: 'adventure',
            kor: '모험섬',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 0,
            repeat: 'daily',
          },
          {
            key: 'chaosGate',
            kor: '카오스 게이트',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 3,
            repeat: 'daily',
          },
          {
            key: 'boss',
            kor: '필드 보스',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 1,
            repeat: 'daily',
          },
          {
            key: 'phantom',
            kor: '유령선',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
            checked: true,
            checkCount: 1,
            done: 0,
            order: 4,
            repeat: 'weekly',
          },
          {
            key: 'challengeAbyss',
            kor: '도전 어비스 던전',
            src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
            checked: true,
            checkCount: 2,
            done: 0,
            order: 5,
            repeat: 'weekly',
          },
        ],
      },
    },
  ],
  expedition: [
    {
      key: 'adventure',
      kor: '모험섬',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
      checked: false,
      checkCount: 1,
      done: 1,
      order: 0,
      repeat: 'daily',
    },
    {
      key: 'boss',
      kor: '필드 보스',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 1,
      repeat: 'daily',
    },
    {
      key: 'seaVoyage',
      kor: '항해 협동',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/e02005b3beac427690c3f97360bad562.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 2,
      repeat: 'daily',
    },
    {
      key: 'chaosGate',
      kor: '카오스 게이트',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
      checked: false,
      checkCount: 1,
      done: 0,
      order: 3,
      repeat: 'daily',
    },
    {
      key: 'phantom',
      kor: '유령선',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
      checked: false,
      checkCount: 1,
      done: 1,
      order: 4,
      repeat: 'weekly',
    },
    {
      key: 'challengeAbyss',
      kor: '도전 어비스 던전',
      src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
      checked: false,
      checkCount: 2,
      done: 0,
      order: 5,
      repeat: 'weekly',
    },
    {
      key: 'rehearsal',
      kor: '리허설',
      src: '/assets/icon/cooxayton.png',
      checked: false,
      checkCount: 3,
      done: 0,
      order: 6,
      repeat: 'weekly',
    },
    {
      key: 'dejavu',
      kor: '데자뷰',
      src: '/assets/icon/abrelschud.png',
      checked: false,
      checkCount: 4,
      done: 0,
      order: 7,
      repeat: 'weekly',
    },
  ],
  refreshDate: '202205300600',
  refreshWeek: '202206010600',
  recentSchedule: {
    daily: [
      {
        key: 'chaos',
        kor: '카오스 던전',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/17012ef4ba7d431daa0884121bb2d912.png',
        checked: true,
        checkCount: 2,
        done: 0,
        order: 0,
        repeat: 'daily',
      },
      {
        key: 'guardian',
        kor: '가디언 토벌',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
        checked: true,
        checkCount: 2,
        done: 0,
        order: 1,
        repeat: 'daily',
      },
      {
        key: 'epona',
        kor: '에포나 의뢰',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
        checked: true,
        checkCount: 3,
        done: 0,
        order: 2,
        repeat: 'daily',
      },
      {
        key: 'custom0',
        kor: '커스텀 컨텐츠',
        custom: 'y',
        done: 0,
        checkCount: 3,
        repeat: 'daily',
        checked: true,
      },
    ],
    weekly: [
      {
        key: 'weeklyEpona',
        kor: '주간 에포나 의뢰',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/4cc6f903b4a74cd7bd232b89298d6c3d.png',
        checked: true,
        checkCount: 3,
        done: 0,
        order: 0,
        repeat: 'weekly',
      },
      {
        key: 'challengeGuardian',
        kor: '도전 가디언 토벌',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/af2a2116f60441389eaa8a8db3624f26.png',
        checked: true,
        checkCount: 3,
        done: 0,
        order: 1,
        repeat: 'weekly',
      },
      {
        key: 'Abyss',
        kor: '어비스 던전',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
        checked: true,
        checkCount: 1,
        done: 0,
        order: 2,
        repeat: 'weekly',
      },
      {
        key: 'baltan',
        kor: '발탄',
        src: '/assets/icon/baltan.png',
        checked: true,
        checkCount: 2,
        done: 0,
        order: 5,
        repeat: 'weekly',
      },
      {
        key: 'cooxayton',
        kor: '쿠크세이튼',
        src: '/assets/icon/cooxayton.png',
        checked: true,
        checkCount: 3,
        done: 0,
        order: 7,
        repeat: 'weekly',
      },
      {
        key: 'abrelschud',
        kor: '아브렐슈드',
        src: '/assets/icon/abrelschud.png',
        checked: true,
        checkCount: 6,
        done: 0,
        order: 8,
        repeat: 'weekly',
      },
    ],
    expedition: [
      {
        key: 'adventure',
        kor: '모험섬',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/f9a83e3dcea640118b47ae6e8d8a1370.png',
        checked: true,
        checkCount: 1,
        done: 0,
        order: 0,
        repeat: 'daily',
      },
      {
        key: 'chaosGate',
        kor: '카오스 게이트',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/91dcab783f024124b71c67d21a849298.png',
        checked: true,
        checkCount: 1,
        done: 0,
        order: 3,
        repeat: 'daily',
      },
      {
        key: 'boss',
        kor: '필드 보스',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/379ca84cd8094b66a51af11eafbf84d1.png',
        checked: true,
        checkCount: 1,
        done: 0,
        order: 1,
        repeat: 'daily',
      },
      {
        key: 'phantom',
        kor: '유령선',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/68b76b3910b54b3e9a490391753a7ad9.png',
        checked: true,
        checkCount: 1,
        done: 0,
        order: 4,
        repeat: 'weekly',
      },
      {
        key: 'challengeAbyss',
        kor: '도전 어비스 던전',
        src: 'https://cdn-lostark.game.onstove.com/uploadfiles/notice/a8d7c95daca24df0a33f561136de379a.png',
        checked: true,
        checkCount: 2,
        done: 0,
        order: 5,
        repeat: 'weekly',
      },
    ],
  },
};
